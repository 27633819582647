<template>
  <div :class="finalClass">
    <span
      v-if="spinToggle"
      :class="`spinner-border spinner-border-sm ${!emptySlot ? 'mr-3' : ''}`"
    ></span>
    <slot v-if="!emptySlot">Loading...</slot>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "basic",
    },
    type: {
      type: String,
      default: "alert",
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
    emptySlot: {
      type: Boolean,
      default: false,
    },
  },
  name: "Alert",
  computed: {
    spinToggle: function () {
      if (this.hideLoader) {
        return false;
      }
      if (!this.$slots.default) {
        return true;
      }

      if (this.$slots.default && this.spinner) {
        return true;
      }

      return false;
    },
    finalClass: function () {
      let classes = ["alert", `alert-${this.color}`];
      let finalClass = classes
        .filter((c) => {
          if (c) {
            if (typeof c == "string") {
              return c;
            }
          }
        })
        .join(" ");

      return finalClass;
    },
  },
};
</script>
