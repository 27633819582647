<template>
  <div class="mt-5 availability-page">
    <h1>Availability</h1>
    <div
      class="
        availability-container
        row
        mx-0
        border
        radius-36
        overflow-hidden
        theme-card
        p-2
      "
      v-if="!loading"
    >
      <div class="col-12 col-lg-6 border-right border-bottom p-4">
        <h2>Weekly hours</h2>
        <div class="weekly-container">
          <div
            class="day-container"
            v-for="(dayData, key) in data.weekDays"
            :key="key"
          >
            <div class="form-check day-checkbox-container">
              <input
                class="form-check-input pointer-cursor"
                type="checkbox"
                v-model="dayData.checked"
                @input="!noEdit && toggleDay(dayData)"
                :disabled="noEdit"
                :id="`${key}-id`"
              />
              <label
                class="font-weight-bold pointer-cursor text-uppercase"
                style="font-size: 14px"
                :for="`${key}-id`"
              >
                {{ key.substring(0, 3) }}
              </label>
            </div>
            <div class="day-intervals-container">
              <div
                class="text-muted"
                v-if="!dayData.checked || !dayData.intervals.length"
              >
                Unavailable
              </div>
              <div v-if="!processing">
                <div
                  v-for="(interval, intervalIndex) in dayData.intervals"
                  :key="intervalIndex"
                >
                  <div
                    class="d-flex align-items-center mb-2"
                    :class="interval.invalid ? 'invalid-container' : ''"
                  >
                    <input
                      type="text"
                      v-cleave="timeOnlyCleave"
                      class="form-control start-input"
                      style="width: 90px"
                      placeholder="HH:MM"
                      :disabled="noEdit"
                      v-model="interval.from"
                      @change="!noEdit && dayIntervalChanged(dayData, interval)"
                    />
                    <div class="mx-1 px-1">-</div>
                    <input
                      type="text"
                      v-cleave="timeOnlyCleave"
                      class="form-control end-input"
                      style="width: 90px"
                      placeholder="HH:MM"
                      v-model="interval.to"
                      :disabled="noEdit"
                      @change="!noEdit && dayIntervalChanged(dayData, interval)"
                    />
                    <button
                      v-if="!noEdit"
                      class="day-action-btn btn btn-sm ml-3"
                      @click="removeDayInterval(dayData, intervalIndex)"
                    >
                      <i class="fas fa-lg fa-times"></i>
                    </button>
                  </div>
                  <div class="availability-error" v-if="interval.invalid">
                    {{ interval.error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="day-actions-container" v-if="!noEdit">
              <button
                class="day-action-btn btn btn-sm"
                @click="addDayInterval(dayData)"
              >
                <i class="fas fa-lg fa-plus"></i>
              </button>
              <div class="btn-group">
                <button
                  type="button"
                  class="day-action-btn btn btn-sm"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="openCloneMenu(key)"
                >
                  <i class="far fa-lg fa-clone"></i>
                </button>
                <div
                  class="dropdown-menu"
                  :ref="`clone-menu_${key}`"
                  @click="$event.stopPropagation()"
                >
                  <h3 class="mx-3 my-2">
                    <i class="far fa-clone mr-2"></i>Clone Times To...
                  </h3>
                  <div class="dropdown-divider"></div>
                  <div
                    class="dropdown-item px-3"
                    v-for="(item, itemKey) in cloneData"
                    :key="itemKey"
                  >
                    <label
                      class="
                        font-weight-bold
                        pointer-cursor
                        mb-0
                        w-100
                        relative-position
                      "
                      style="font-size: 14px"
                    >
                      {{ item.label }}

                      <input
                        class="clone-checkbox-input pointer-cursor"
                        type="checkbox"
                        v-model="item.checked"
                        :disabled="item.disabled"
                      />
                    </label>
                  </div>
                  <div class="dropdown-divider" v-if="!noEdit"></div>
                  <div class="dropdown-item px-3" v-if="!noEdit">
                    <button class="btn btn-theme w-100" @click="cloneTime(key)">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 border-left border-bottom p-4">
        <h2>Date-specific hours</h2>
        <p class="text-muted" v-if="!noEdit">
          Override your availability for specific dates when your hours differ
          from your regular weekly hours.
        </p>
        <button
          class="btn btn-outline-dark mt-3 radius-99"
          v-if="!noEdit"
          @click="openCustomDateModal()"
        >
          <i class="fas fa-plus mr-2"></i>Add date-specific hours
        </button>

        <div
          v-if="data.customDates.length"
          class="rules-container mt-5 table-container"
        >
          <table class="w-100 mb-0 table table-hover">
            <tr
              class="pointer-cursor"
              v-for="(item, itemIndex) in data.customDates"
              :key="item.dateValue"
              @click="!noEdit && updateCustomDateIntervals(item)"
            >
              <td class="pl-3 py-3 text-left" style="vertical-align: top">
                {{ prettyDate(item.dateValue) }}
              </td>
              <td class="pl-3 py-3 text-left" style="vertical-align: top">
                <div class="text-muted" v-if="!item.intervals.length">
                  Unavailable
                </div>
                <div
                  class="px-3"
                  style="white-space: nowrap"
                  v-for="interval in item.intervals"
                  :key="interval.from"
                >
                  {{ interval.from }} - {{ interval.to }}
                </div>
              </td>
              <td
                class="text-right pr-3"
                style="vertical-align: middle"
                v-if="!noEdit"
              >
                <button class="btn btn-sm" @click="removeCustomDate(itemIndex)">
                  <i class="fas fa-lg fa-times"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="muted" v-if="!data.customDates.length && noEdit">
          No specific dates added yet.
        </div>
      </div>
      <div
        class="d-flex w-100 py-4 justify-content-center border-top"
        v-if="
          !noEdit &&
          (loggedInUser.isAdmin ||
            loggedInUser.isManagingAdmin ||
            loggedInUser.id == currentUserId)
        "
      >
        <save
          :saving="saving"
          classes="btn btn-theme"
          savingText="Saving Changes..."
          :disabled="data.invalid"
          @click="saveChanges"
        >
          Save Changes
        </save>
      </div>
    </div>
    <alert v-if="loading" class="light-shadow"></alert>

    <modal
      class="custom-date-modal"
      name="custom-date-modal"
      transition="pop-out"
      :width="400"
      :height="'auto'"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <div class="d-flex align-items-start">
            <h3
              class="px-3 pt-3 mb-0 text-left"
              style="font-size: 19px; line-height: 1.2"
            >
              Select the date(s) you want to assign specific hours
            </h3>
            <button class="btn p-2 m-2 border-0" @click="closeCustomDateModal">
              <i class="fas fa-times fa-lg"></i>
            </button>
          </div>
        </div>
        <div class="card-body pt-4 px-0">
          <div class="relative-position">
            <div class="d-flex justify-content-between col-12 mb-5 py-0 px-4">
              <v-calendar
                :attributes="calendarAttributes"
                @dayclick="onDayClick"
                is-expanded
              />
            </div>

            <div
              v-if="!modalValues.processing && modalValues.customDates.length"
              class="border-top border-top-2 px-4 py-2 w-100 relative-position"
              style="background-color: #edf2f9"
            >
              <p class="small font-weight-bold">
                What hours are you available?
              </p>
              <div
                class="text-muted d-flex align-items-center"
                style="min-height: 46px"
                v-if="!modalValues.intervals.length"
              >
                Unavailable
              </div>
              <div v-if="modalValues.intervals.length">
                <div
                  v-for="(interval, intervalIndex) in modalValues.intervals"
                  :key="intervalIndex"
                >
                  <div
                    class="d-flex align-items-center mb-2"
                    :class="interval.invalid ? 'invalid-container' : ''"
                  >
                    <input
                      type="text"
                      v-cleave="timeOnlyCleave"
                      class="form-control"
                      style="width: 90px"
                      placeholder="HH:MM"
                      v-model="interval.from"
                      @change="dayIntervalChanged(modalValues, interval)"
                    />
                    <div class="mx-1 px-1">-</div>
                    <input
                      type="text"
                      v-cleave="timeOnlyCleave"
                      class="form-control"
                      style="width: 90px"
                      placeholder="HH:MM"
                      v-model="interval.to"
                      @change="dayIntervalChanged(modalValues, interval)"
                    />
                    <button
                      class="day-action-btn btn btn-sm ml-3"
                      @click="removeDateInterval(intervalIndex)"
                    >
                      <i class="fas fa-lg fa-times"></i>
                    </button>
                  </div>
                  <div class="availability-error" v-if="interval.invalid">
                    {{ interval.error }}
                  </div>
                </div>
              </div>
              <button
                class="date-action-btn btn btn-sm"
                @click="addDateInterval()"
              >
                <i class="fas fa-lg fa-plus"></i>
              </button>
            </div>
          </div>
          <div
            class="p-4 d-flex justify-content-between border-top border-top-2"
          >
            <button class="btn btn-light col-5" @click="closeCustomDateModal()">
              Cancel
            </button>
            <button
              class="btn btn-theme col-5"
              @click="addModalCustomDates()"
              :disabled="hasInvalidDatesItems()"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import helpers from "@/utils/helpers";
import { orderBy } from "lodash";

export default {
  name: "Availability",
  components: {},
  props: {
    currentUserId: {
      type: Number,
      required: true,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        weekDays: {
          sunday: {
            checked: false,
            intervals: [],
          },
          monday: {
            checked: false,
            intervals: [],
          },
          tuesday: {
            checked: false,
            intervals: [],
          },
          wednesday: {
            checked: false,
            intervals: [],
          },
          thursday: {
            checked: false,
            intervals: [],
          },
          friday: {
            checked: false,
            intervals: [],
          },
          saturday: {
            checked: false,
            intervals: [],
          },
        },
        customDates: [],
        invalid: false,
      },
      modalValues: {
        customDates: [],
        intervals: [],
        processing: false,
        invalid: true,
      },
      defaultCloneData: {
        sunday: {
          checked: false,
          disabled: false,
          label: "Sunday",
        },
        monday: {
          checked: false,
          disabled: false,
          label: "Monday",
        },
        tuesday: {
          checked: false,
          disabled: false,
          label: "Tuesday",
        },
        wednesday: {
          checked: false,
          disabled: false,
          label: "Wednesday",
        },
        thursday: {
          checked: false,
          disabled: false,
          label: "Thursday",
        },
        friday: {
          checked: false,
          disabled: false,
          label: "Friday",
        },
        saturday: {
          checked: false,
          disabled: false,
          label: "Saturday",
        },
      },
      cloneData: Object.assign({}, this.defaultCloneData),
      timeOnlyCleave: {
        time: true,
        timePattern: ["h", "m"],
      },
      processing: false,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.auth.user,
      loading: (state) => state.availability.isLoading,
      saving: (state) => state.availability.isSaving,
    }),
    calendarAttributes() {
      return this.modalValues?.customDates?.map((date) => ({
        highlight: true,
        dates: `${date}T00:00:00.000000Z`,
      }));
    },
  },
  mounted() {
    this.getUserAvailability({ user_id: this.currentUserId }).then((data) => {
      if (data) {
        this.data.customDates = data
          .filter((item) => item.type == "date")
          .map((object) => {
            return {
              dateValue: object.type_data,
              intervals: object.intervals,
            };
          });

        data
          .filter((item) => item.type == "wday")
          .forEach((item) => {
            this.data.weekDays[item.type_data].intervals = item.intervals;
            this.data.weekDays[item.type_data].checked = item.intervals?.length;
          });
      }
      this.$forceUpdate();
    });
  },
  methods: {
    ...mapActions({
      getUserAvailability: "availability/getUserAvailability",
      updateAvailability: "availability/updateUserAvailability",
    }),
    addDayInterval(dayData) {
      if (!dayData.checked) {
        dayData.checked = true;
        dayData.intervals.push({ from: "09:00", to: "17:00" });
      } else {
        dayData.intervals.push({ from: "", to: "" });
      }
    },
    removeDayInterval(dayData, intervalindex) {
      this.data.invalid = false;
      this.processing = true;
      dayData.intervals.splice(intervalindex, 1);
      if (!dayData.intervals.length) {
        dayData.checked = false;
      } else {
        dayData.intervals.forEach((item) => {
          if (this.isValidTime(item.from) && this.isValidTime(item.to)) {
            item.invalid = false;
            item.error = undefined;
          }
        });
        this.hasTimeOverlap(dayData);
      }
      setTimeout(() => {
        this.processing = false;
      }, 0);
    },
    dayIntervalChanged(dayData, interval) {
      this.data.invalid = false;
      if (!this.isValidTime(interval.from) || !this.isValidTime(interval.to)) {
        interval.invalid = true;
        interval.error = "Invalid time";
      } else if (this.isEndTimeEarlier(interval)) {
        interval.invalid = true;
        interval.error = "Choose an end time later than the start time.";
      } else {
        interval.invalid = false;
        interval.error = undefined;
      }
      if (interval.invalid) {
        this.data.invalid = true;
      }

      if (dayData.intervals.length) {
        dayData.intervals.forEach((item) => {
          if (
            this.isValidTime(item.from) &&
            this.isValidTime(item.to) &&
            !this.isEndTimeEarlier(item)
          ) {
            item.invalid = false;
            item.error = undefined;
          }
        });
        this.hasTimeOverlap(dayData);
      }
      this.$forceUpdate();
    },
    isValidTime(timeInput) {
      const timePattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;
      return timePattern.test(timeInput);
    },
    hasTimeOverlap(dayData) {
      let hasOverlap = false;

      dayData.intervals.forEach((interval1, index1) => {
        if (
          this.isValidTime(interval1.from) &&
          this.isValidTime(interval1.to)
        ) {
          dayData.intervals.slice(index1 + 1).forEach((interval2) => {
            if (
              this.isValidTime(interval2.from) &&
              this.isValidTime(interval2.to)
            ) {
              if (this.isTimeOverlap(interval1, interval2)) {
                interval2.invalid = interval1.invalid = true;
                interval2.error = interval1.error =
                  "Times overlap with another set of times.";
                hasOverlap = true;
                this.data.invalid = true;
              }
            }
          });
        }
      });
      return hasOverlap;
    },
    isTimeOverlap(interval1, interval2) {
      // Convert string time to Date objects
      const startTime1 = new Date(`2000-01-01T${interval1.from}`);
      const endTime1 = new Date(`2000-01-01T${interval1.to}`);
      const startTime2 = new Date(`2000-01-01T${interval2.from}`);
      const endTime2 = new Date(`2000-01-01T${interval2.to}`);
      // Check for overlapping
      return startTime1 < endTime2 && endTime1 > startTime2;
    },
    isEndTimeEarlier(interval) {
      // Convert string time to Date objects
      const startTime = new Date(`2000-01-01T${interval.from}`);
      const endTime = new Date(`2000-01-01T${interval.to}`);
      // Check for overlap
      return endTime <= startTime;
    },
    toggleDay(dayData) {
      if (dayData.checked) {
        dayData.intervals = [];
      } else {
        dayData.intervals.push({ from: "09:00", to: "17:00" });
      }
    },
    cloneTime(srcKey) {
      this.$refs[`clone-menu_${srcKey}`][0].classList.remove("show");
      const targetDays = [];
      for (let key in this.cloneData) {
        if (key !== srcKey && this.cloneData[key].checked) {
          targetDays.push(key);
        }
      }
      const srcIntervals = this.data.weekDays[srcKey].intervals;
      targetDays.forEach((day) => {
        this.data.weekDays[day].intervals = JSON.parse(
          JSON.stringify(srcIntervals)
        );
        this.data.weekDays[day].checked = srcIntervals.length;
      });
    },
    openCloneMenu(key) {
      this.cloneData = JSON.parse(JSON.stringify(this.defaultCloneData));
      this.cloneData[key].checked = true;
      this.cloneData[key].disabled = true;
    },
    openCustomDateModal(defaultData) {
      if (defaultData) {
        this.modalValues = JSON.parse(JSON.stringify(defaultData));
      } else {
        this.modalValues = {
          customDates: [],
          intervals: [],
        };
      }
      this.$modal.show("custom-date-modal");
    },
    closeCustomDateModal() {
      this.$modal.hide("custom-date-modal");
    },
    onDayClick: function (day) {
      const idx = this.modalValues.customDates.findIndex((id) => id === day.id);
      if (idx >= 0) {
        this.modalValues.customDates.splice(idx, 1);
        if (!this.modalValues.customDates.length) {
          this.modalValues.intervals = [];
        }
      } else if (!day.el.classList.contains("is-disabled")) {
        this.modalValues.customDates.push(day.id);
        if (this.modalValues.customDates.length == 1) {
          const existingDate = this.data.customDates.filter(
            (item) => item.dateValue == day.id
          )[0];
          if (existingDate) {
            this.modalValues.intervals.push(...existingDate.intervals);
          } else {
            this.modalValues.intervals.push({ from: "09:00", to: "17:00" });
          }
        }
      }
    },
    addDateInterval: function () {
      if (this.modalValues.intervals.length) {
        this.modalValues.intervals.push({ from: "", to: "" });
      } else {
        this.modalValues.intervals.push({ from: "09:00", to: "17:00" });
      }
    },
    removeDateInterval: function (intervalindex) {
      this.modalValues.processing = true;
      this.data.invalid = false;
      this.modalValues.intervals.splice(intervalindex, 1);
      this.modalValues.intervals.forEach((item) => {
        if (this.isValidTime(item.from) && this.isValidTime(item.to)) {
          item.invalid = false;
          item.error = undefined;
        }
      });
      this.hasTimeOverlap(this.modalValues);
      setTimeout(() => {
        this.modalValues.processing = false;
        this.$forceUpdate();
      }, 0);
    },
    addModalCustomDates: function () {
      if (!this.hasInvalidDatesItems()) {
        let processingData = JSON.parse(JSON.stringify(this.data.customDates));
        this.modalValues.customDates.forEach((dateItem) => {
          processingData = processingData.filter(
            (item) => item.dateValue != dateItem
          );
          processingData.push({
            dateValue: dateItem,
            intervals: this.modalValues.intervals.filter(
              (interval) => interval.from && interval.to
            ),
          });
        });
        processingData = orderBy(processingData, ["dateValue"], ["asc"]);
        this.data.customDates = JSON.parse(JSON.stringify(processingData));
      }
      this.closeCustomDateModal();
    },
    hasInvalidDatesItems: function () {
      if (!this.modalValues.customDates.length) {
        return true;
      } else {
        return (
          this.modalValues.intervals.filter((item) => item.invalid).length !== 0
        );
      }
    },
    removeCustomDate: function (index) {
      this.data.customDates.splice(index, 1);
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date, "MMM D, YYYY");
    },
    updateCustomDateIntervals: function (item) {
      const defaultData = {
        customDates: [item.dateValue],
        intervals: item.intervals,
      };
      this.openCustomDateModal(defaultData);
    },
    saveChanges: function () {
      if (this.data.invalid) {
        return;
      }
      const availabilityData = [];
      Object.keys(this.data.weekDays).forEach((key) => {
        availabilityData.push({
          type: "wday",
          type_data: key,
          intervals: this.data.weekDays[key].intervals,
        });
      });
      this.data.customDates.forEach((item) => {
        availabilityData.push({
          type: "date",
          type_data: item.dateValue,
          intervals: item.intervals,
        });
      });
      this.updateAvailability({
        user_id: this.currentUserId,
        availability: availabilityData,
      });
    },
  },
};
</script>

<style lang="scss">
.custom-date-modal {
  overflow: auto;
  .v--modal {
    transition: all 0.5s ease-in-out;
  }
}
.text-muted {
  color: #677a97 !important;
}

.availability-page {
  .availability-error {
    font-size: 14px;
    color: rgb(179 31 31 / 80%);
    margin-bottom: 12px;
  }
  .invalid-container {
    input {
      border-color: darkred;
    }
  }
  .rules-container {
    flex: 1 1 0;
    overflow-y: auto;
  }
  .table-hover {
    tr:hover {
      background-color: #edf2f9;
    }
  }
  .day-container {
    min-height: 46px;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    position: relative;

    @media (max-width: 510px) {
      flex-direction: column;
    }
  }
  .day-checkbox-container {
    width: 88px;
    margin-top: 10px;
    flex-shrink: 0;
    padding-left: 1.5rem;
  }
  .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-left: -1.5rem;
  }
  .day-intervals-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 46px;
  }
  .day-actions-container {
    display: flex;
    position: relative;
  }
  .day-action-btn {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    padding: 4px;
    border-radius: 4px !important;
    background-color: transparent;
    color: #232222;
    transition: all 0.3s;

    &:hover {
      background-color: #f2f2f2;
    }
  }
  .dropdown-menu {
    min-width: 200px;

    .clone-checkbox-input {
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
    }
  }
  .date-action-btn {
    position: absolute;
    top: 44px;
    right: 12px;
  }
}
</style>
