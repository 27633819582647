<template>
  <div :class="finalClass">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Badge",
  props: {
    type: {
      type: String,
      default: "badge",
    },
    soft: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
  },
  computed: {
    pill: function () {
      return this.type == "pill" ? `badge-pill` : false;
    },
    softClass: function () {
      return this.soft ? `badge-soft-${this.color} ` : false;
    },
    finalClass: function () {
      let classes = ["badge", `badge-${this.color}`, this.softClass, this.pill];
      let finalClass = classes
        .filter((c) => {
          if (c) {
            if (typeof c == "string") {
              return c;
            }
          }
        })
        .join(" ");

      return finalClass;
    },
  },
};
</script>
